$animation-speed: 250ms;

$b: #000;
$w: #FFF;

$color-main-lightest:    #e7d094;
$color-main-light-muted: #cbb6a5;
$color-main-light:       #68472b;
$color-main:             #382c22;
$color-main-dark:        #302d2a;
$color-main-darkest-bright: #200660;
$color-main-darkest:     #181717;

$color-border:           #c9c6cd;
$color-stop:             #ad0d0d;
$color-near-white:       #fefefe;
$color-neutral:          #dddddd;
$color-neutral-dark:     #646464;
$color-neutral-darkest:  #2a2a2a;

$color-text:             $color-near-white;
$color-text-dark:        $color-main;
$color-text-subtle:      #add6e3;
$color-button:           $color-main;
$color-button-text:      $color-near-white;
$color-button-skip:      #a73232;

$color-alert: #d52222;
$color-debug: #565656;