* {
  -webkit-tap-highlight-color: rgba(0,0,0,0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

body {
  -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;             /* prevent webkit from resizing text to fit */
  -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
  font-family: system-ui, -apple-system, -apple-system-font, 'Segoe UI', 'Roboto', sans-serif;
  font-size: 12px;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
  width: 100%;
  color: $w;
}

/* Portrait layout (default) */
#app {
  height: 100%;
}

/* Landscape layout (with min-width) */
@media screen and (min-aspect-ratio: 1/1) and (min-width:400px) {
  #app {

  }
}

body {
  background: $color-main-dark url('../img/bg_coffee.png') no-repeat center;
  background-size: 70%;
  font-family: 'Nunito', 'Ubuntu', sans-serif;
  font-size: 19px;
}
.wrapper {
  width: 75%;
  margin: 0 auto;
}
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: $color-main;
  color: $color-near-white;
  &:hover, &:focus {
    background-color: darken($color-main, 50%);
    color: $color-near-white;
  }
  @include responsive(portrait) {
    padding: 0.35em 0.6em;
    margin-bottom: 0.6rem;
  }
  &.debug {
    background-color: $color-debug;
  }
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: $color-main;
  border-bottom: 20px solid $color-main-light;
  box-shadow: 0 5px 10px rgb(30 7 39 / 30%) !important;
  font-size: 12px;
  color: $w;
  z-index: 300;
  h2.title {
    display: inline-block;
    width: 62%;
    font-size: 3rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-style: normal;
    font-weight: normal;
    padding: 5px 40px;
    @include responsive(landscape) {
      width: 72%;
      padding: 5px 80px 1px 20px;
      font-size: 2rem;
    }
    @include responsive(portrait) {
      padding: 10px 60px 1px 20px;
      font-size: 1.5rem;
      width: 100%;
    }
  }
  #settingsbutton {
    position: fixed;
    top: 0;
    right: 0;
    width: 52px;
    height: 52px;
    background: url('../img/settings.png') no-repeat center;
    background-size: 24px;
    cursor: pointer;
    &.active {
      background-image: url('../img/close.png');
    }
  }
}

#particlecanvas {
  display: grid;
  grid-template-columns: 1fr;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 150;
  pointer-events: none;
}

#maincontent {
  padding: 75px 0 0 0;
  max-width: 100vw;
  height: calc(100% - 73px);
  overflow: hidden;
  background-position: center;

  .page {
    display: none;
    padding: 40px 0;
    @include responsive(portrait) {
      padding: 10px 0;
    }
  }

  #blockinteraction {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // background-color: #00b8ff47;
    pointer-events: none;
    transition: opacity $animation-speed;
    z-index: 500;
    &.active {
      pointer-events: auto;
      opacity: 1;
    }
    &.paused {
      background-color: #133081c7;
    }
  }

}