#maincontent {
  #index {
    #welcome, #overview, #reset {
      padding: 0 20px;
      color: $color-main-light-muted;
      &.hidden {
        display: none;
      }
    }
    #welcome p {
      position: relative;
      display: inline-block;
    }
    #overview {
      padding-bottom: 10px;
    }
    #orders {
      .order {
        position: relative;
        background-color: $color-main-darkest;
        .orderdata {
          padding: 10px 55px 10px 20px;
          .who {
            min-width: 110px;
            color: darken($color-text, 20%);
          }
          .who, .what, .code {
            display: inline-block;
          }
          .what {
            min-width: 140px;
            margin-left: 10px;
            .other {
              color: $color-main-light-muted;
            }
          }
          .code {
            color: $color-neutral-dark;
            &.unknown {
              color: $w;
              padding: 0 10px;
            }
          }
          .options {
            position: absolute;
            top: 3px;
            right: 0;
            padding: 10px 14px;
            img {
              width: 18px;
            }
          }
        }
        .menu {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s;
          color: $color-main-darkest;
          background-color: $w;
          &.expanded {
            max-height: 170px;
          }
          > div {
            padding: 6px 20px;
            border-bottom: 1px solid $color-neutral-dark;
            cursor: pointer;
            &.remove {
              color: $color-alert;
            }
          }
        }
        .selectcode {
          padding: 10px 20px 5px;
          background-color: #68472b;
          p {
            margin-bottom: 10px;
          }
          .button {
            border-radius: 8px;
            color: $b;
            background-color: $w;
            font-size: 19px;
            text-align: left;
            border-radius: 8px;
            margin-right: 10px;
          }
        }
        .addnote {
          background-color: $color-neutral-darkest;
          textarea {
            display: block;
            width: calc(100% - 20px);
            height: 150px;
            margin-left: 20px;
            padding: 5px 10px;
          }
          .submit {
            margin-left: 20px;
            background-color: $color-main-light;
          }
        }
        .note {
          background-color: #52504d;
          color: white;
          width: 100%;
          padding: 5px 15px;
          margin-left: 20px;
        }
        &.done {
          .who, .what, .code, .note {
            color: $color-neutral-dark;
            text-decoration: line-through;
          }
          .options {
            display: none;
          }
          .note {
            background-color: $color-main-dark;
          }
        }
      }
    }
    #reset {
      display: inline-block;
      margin: 14px 0 80px;
    }
    #settings, #selector {
      background-color: $color-main-dark;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 100vh;
      left: 0;
      padding: 90px 20px 0 20px;
      overflow-y: scroll;
      z-index: 100;
      transition: top 250ms;
      &.active {
        top: 0;
      }
      .button {
        font-size: 19px;
        text-align: left;
        border-radius: 8px;
        background-color: $color-main-darkest;
        &.other {
          color: $color-main-light-muted;
        }
      }
      #sortpeople, #people, #drinks {
        padding-bottom: 70px;
        .button {
          display: block;
        }
      }
      #drinks {
        display: none;
        #question {
          color: $w;
          margin-bottom: 10px;
        }
      }
    }
    #settings {
      top: -100vh;
      background-color: $color-neutral-darkest;
      z-index: 250;
      #audiotoggle {
        margin-bottom: 15px;
        label {
          margin-left: 10px;
        }
      }
      hr {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 15px;
      }
      #sortpeople {
        padding-bottom: 10px;
        .button {
          width: 50%;
          min-width: 150px;
        }
      }
    }
    #back {
      position: fixed;
      bottom: 10px;
      left: 20px;
      width: 15vw;
      filter: invert(27%) sepia(39%) saturate(610%) hue-rotate(346deg) brightness(95%) contrast(90%);
      z-index: 200;
      &.hidden {
        display: none;
      }
    }
    #add {
      position: fixed;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      padding: 15px;
      cursor: pointer;
      div {
        width: 15vw;
        height: 15vw;
        text-align: center;
        background-color: $w;
        border-radius: 50%;
        color: $b;
        font-size: 10vw;
        box-shadow: 1px 2px 6px 2px #00000047;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}