@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// @include responsive(portrait) {}
@mixin responsive($class) {
  @if $class == landscape {
    @media screen and (max-width: 960px) { @content; }
  }
  @else if $class == portrait {
    @media screen and (max-width: 600px) { @content; }
  }
  @else {
    @warn "Unrecognized responsive preset";
  }
}